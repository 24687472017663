import { useEffect, useState } from 'react';
import BrandOne from '../images/brand/brand-01.svg';
import BrandTwo from '../images/brand/brand-02.svg';
import BrandThree from '../images/brand/brand-03.svg';
import BrandFour from '../images/brand/brand-04.svg';
import BrandFive from '../images/brand/brand-05.svg';
import axios from 'axios';
import { API_KEY, API_SECRET, BASEURL } from '../pages/Constants';
import { getAccessToken } from '../services/http-common';
import { Link } from 'react-router-dom';
interface orderInterface {
  orderId?: any | null;
  amount: string;
  date: string;
  paymentMode:string;
  status: string;
  orderTotal:string;
}
const TableOne = () => {
  const [orders, setOrders] = useState<orderInterface[]>([]);
  const accessToken = getAccessToken();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASEURL}orders/recent`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'multipart/form-data',
            'X-API-SECRET':`${API_SECRET}`,
            'X-API-KEY':`${API_KEY}`
          },       
          method: "GET",
        });
        const ordersData = await response.data;
        setOrders(ordersData);
      } catch (error) {
        console.error("Error fetching customer data:", error);
        return null;
      }
}
    fetchData();
  }, []); // Empty dependency array means this effect runs only once after the component mounts

 
      
  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
        Recent Orders
      </h4>

      <div className="hidden xl:block  flex-col">
        <div className="grid grid-cols-3 rounded-sm bg-gray-2 dark:bg-meta-4 sm:grid-cols-4">
          <div className="p-2.5 xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Order Id
            </h5>
          </div>
          <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Date
            </h5>
          </div>
          {/* <div className="p-2.5 text-center xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Amount
            </h5>
          </div> */}
          <div className="hidden p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Status
            </h5>
          </div>
          <div className="hidden p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Amount
            </h5>
          </div>
        </div>

        {orders && orders.map((order,index) => (  
           <Link
           className="hover:text-primary text-primary"
           to={`/orders/details/${order.orderId}`}
         >
        <div key={index} className="grid grid-cols-3 border-b border-stroke dark:border-strokedark sm:grid-cols-4">
          <div className="flex items-center gap-3 p-2.5 xl:p-5">
           
            <p className=" text-black dark:text-white sm:block">{order.orderId}</p>
          </div>

          <div className="flex items-center justify-center p-2.5 xl:p-5">
            <p className="text-black dark:text-white">{order.date.toString().split("T")[0]}</p>
          </div>

          <div className=" xl:flex items-center justify-center p-2.5 xl:p-5">
          {order.status==="PAYMENT_DONE" ?(
                    <p className="text-white bg-success text-xs font-base p-1 rounded-sm">{order.status==="PAYMENT_DONE" && order.paymentMode==="cod" ? "PARTIAL_PAYMENT": "ORDER_CONFIRMED"}</p>
                  ):order.status === "PAYMENT_FAILED" || order.status === "PAYMENT_CANCELLED" || order.status === "ORDER_CANCELLED" ?(
                    <p className="text-white bg-danger text-xs font-base p-1 rounded-sm">{order.status}</p>
                  ):(
                    order.status === "ORDER_COMPLETED" ? (
                      <p className="text-white bg-primary text-xs font-base p-1 rounded-sm">{order.status}</p>
                    ):order.status === "ORDER_PENDING" ? (
                      <p className="text-white bg-meta-8 text-xs font-base p-1 rounded-sm">{order.status}</p>
                    ):order.status === "ORDER_PLACED" ? (
                      <p className="text-white bg-meta-10 text-xs font-base p-1 rounded-sm">{order.status}</p>
                    ):(
                      <p className="text-white bg-meta-5 text-xs font-base p-1 rounded-sm">{order.status}</p>

                    )
                  )}          </div>

          <div className=" items-center justify-center p-2.5 sm:flex xl:p-5">
            <p className="text-black dark:text-white">{order.orderTotal}</p>
          </div>

         
        </div>  
        </Link>
))}
       
      </div>

      <div className="xl:hidden sm:block  flex-col">
        <div className="flex justify-between rounded-sm bg-gray-2 dark:bg-meta-4 ">
          <div className="p-2.5 xl:p-5 w-40 items-center">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Order Id
            </h5>
          </div>
          <div className="p-2.5 xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Date
            </h5>
          </div>
          
          <div className=" p-2.5 text-center sm:block xl:p-5">
            <h5 className="text-sm font-medium uppercase xsm:text-base">
              Amount
            </h5>
          </div>
        </div>

        {orders && orders.map((order,index) => (  
           <Link
           className="hover:text-primary text-primary"
           to={`/orders/details/${order.orderId}`}
         >
        <div key={index} className="flex  justify-between border-b border-stroke dark:border-strokedark ">
          <div className=" items-center  p-2.5 xl:p-5 w-auto">
           
            <p className=" text-black dark:text-white ">{order.orderId}</p>
            <div className="w-auto ">
          {order.status==="PAYMENT_DONE" ?(
                    <p className="text-white text-center bg-success text-xs font-base p-0.5 rounded-sm">{order.status==="PAYMENT_DONE" && order.paymentMode==="cod" ? "PARTIAL_PAYMENT": "ORDER_CONFIRMED"}</p>
                  ):order.status === "PAYMENT_FAILED" || order.status === "PAYMENT_CANCELLED" || order.status === "ORDER_CANCELLED" ?(
                    <p className="text-white bg-danger text-center text-xs font-base p-0.5 rounded-sm">{order.status}</p>
                  ):(
                    order.status === "ORDER_COMPLETED" ? (
                      <p className="text-white bg-primary text-center text-xs font-base p-0.5 rounded-sm">{order.status}</p>
                    ):order.status === "ORDER_PENDING" ? (
                      <p className="text-white bg-meta-8 text-center text-xs font-base p-0.5 rounded-sm">{order.status}</p>
                    ):order.status === "ORDER_PLACED" ? (
                      <p className="text-white bg-meta-10  text-center text-xs font-base p-0.5 rounded-sm">{order.status}</p>
                    ):(
                      <p className="text-white bg-meta-5 text-center text-xs font-base p-0.5 rounded-sm">{order.status}</p>

                    )
                  )}          </div>
          </div>

          <div className="flex  p-2.5 xl:p-5">
            <p className="text-black dark:text-white">{order.date.toString().split("T")[0]}</p>
          </div>

          <div className=" items-center p-2.5 sm:flex xl:p-5">
            <p className="text-black dark:text-white">{order.orderTotal}</p>
          </div>

         
        </div>  
        </Link>
))}
       
      </div>
    </div>
  );
};

export default TableOne;
